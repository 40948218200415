.Container {
  width: 100%;
  height: 100%;
  max-width: 400px;
  border-radius: 4px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Container ul {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.CurseContent img {
  border-radius: 4px;
  object-fit: fill;
  width: 90%;
  margin: none;
  padding: none;
  /* max-width: 21.437rem; */
}

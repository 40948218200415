.custom-search .ant-input:focus, 
.custom-search .ant-input:hover {
  border-color: #EC6643 !important; /* Cor da borda quando focado ou em hover */
  box-shadow: none !important; /* Remove a sombra padrão */
}

.custom-search .ant-input-affix-wrapper:focus, 
.custom-search .ant-input-affix-wrapper:hover {
  border-color: #EC6643 !important; /* Cor da borda quando focado ou em hover */
  box-shadow: none !important; /* Remove a sombra padrão */
}

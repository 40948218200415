.ContentInputPreFixed {
    border-bottom: 1px solid #d3d3d7;
    color: #C0C3C9;
    width: 100%;
}

.ContentInputPreFixed:focus-within {
    border-bottom: 3px solid #EC6643;
    width: 100%;
}

.ContentInputPreFixed:focus-within .input {
    width: 100%;
}

.MonthlyInputContent{
    border-bottom: 1px solid #d3d3d7;
    color: #C0C3C9;
    width: 300px;
    height: 47px;
}
.MonthlyInputContent:focus-within {
    border-bottom: 3px solid #EC6643;
    width: 300px;
    height: 47px;
}
.MonthlyInputContent:focus-within .input {
    width: 300px;
}

.select{
    background-color: #fff;
}


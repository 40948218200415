.perspective{
	perspective: 1000px;
	background-color: white;
	height: 100vh;
	
	display: flex;
	justify-content: center;
	align-items: center;

}
.cube {
	position: relative;
	width: 100px; height: 100px;
	transform-style: preserve-3d;
	transform: rotatex(-20deg) rotatey(-140deg) translatez(0);	
/* 	background: rgba(0,0,0,0.2); */
	animation: rotate 16s ease-in-out infinite alternate-reverse;
}

.cube div {
	position: absolute;
	width: 100px; height: 100px;
	opacity: 0.75;
	color: #fff;
	font-size: 20px;
	
}

.cube div:nth-child(1) {
	transform: translatez(50px);
	background-color: #c71722;
	line-height: 100px;
	text-align: center;
	font-size: 16px;
  animation: top 8s 16s ease-in-out infinite;
}
.cube div:nth-child(2) {
	transform: rotateY(90deg) translatez(50px);
	background-color:#2469a9;
	line-height: 100px;
	text-align: center;
	font-size: 16px;
  animation: left 8s 16s ease-in-out infinite;
  
}
.cube div:nth-child(3) {
	transform: rotateY(180deg) translatez(50px);
	background-color:#2469a9;
	  line-height: 100px;
	text-align: center;
	font-size: 16px;
  animation: bottom 8s 16s ease-in-out infinite;
 
}

.cube div:nth-child(4) {
	transform: rotateY(270deg) translatez(50px);
	background-color: #c71722;
	line-height: 100px;
	text-align: center;
	font-size: 16px;
	animation: right 8s 16s ease-in-out infinite;
}

.cube div:nth-child(5) {
	transform: rotateX(90deg) translatez(50px);
	background-color: #f9b800;
	line-height: 100px;
	text-align: center;
	font-size: 16px;
  animation: front 8s 16s ease-in-out infinite;
}

.cube div:nth-child(6) {
	transform: rotateX(270deg) translatez(50px);
	background-color:  #2b2a2a;
	 line-height: 100px;
	text-align: center;
	font-size: 16px;
  animation: back 8s 16s ease-in-out infinite;
 }



@keyframes rotate{
	0%{ transform: rotatex(0) rotatey(0) rotatez(0);}
	100%{ transform: rotateX(360deg) rotateY(720deg) rotateZ(720deg);}
}

@keyframes top{
	0%   { transform: translatez(50px);}
	50%  {transform: translatez(100px);}
	100% {transform: translatez(50px);}
}

@keyframes bottom{
	0%   { transform: rotateY(180deg) translatez(50px);}
	50%  {transform: rotateY(180deg) translatez(100px);}
	100% {transform: rotateY(180deg) translatez(50px);}
}

@keyframes left{
	0% {transform: rotateY(90deg) translatez(50px) }
	50% {transform: rotateY(90deg) translatez(100px) }
	100% {transform: rotateY(90deg) translatez(50px)}
}

@keyframes right{
	0% {transform: rotateY(270deg) translatez(50px)}
	50% {transform: rotateY(270deg) translatez(100px) }
	100% {transform: rotateY(270deg) translatez(50px)}
}
@keyframes front{
	0% {transform: rotateX(90deg) translatez(50px)}
	50% {transform: rotateX(90deg) translatez(100px)}
	100% {transform: rotateX(90deg) translatez(50px)}
}
@keyframes back{
	0% {transform: rotateX(270deg) translatez(50px)}
	50% {transform: rotateX(270deg) translatez(100px)}
	100% {transform: rotateX(270deg) translatez(50px)}
}
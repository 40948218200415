@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@200;300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700');
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700');
@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700');

#avisoExpira {
    display: flex;
    align-items: center;
    justify-content: center;
}

#avisoExpira {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-designer {
    position: relative; /* Adiciona posição relativa ao contêiner do modal */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: white;
    border-radius: 1rem;
    height: 20%;
    width: 20%; 
    max-width: 30rem;
}

.modal-designer.full-size {
    width: 100%;
}

.modal-designer.size-xl {
    max-width: 70rem;
}

.modal-designer.size-lg {
    max-width: 55rem;
}

.modal-designer.size-md {
    max-width: 40rem;
}

#aviso {
    justify-content: center;
    text-align: center;
    font-size: 50px;
    margin-top: -3%;
    font-family: Poppins, sans-serif;
}

#subtititulo{
    justify-content: center;
    text-align: center;
    font-size: 30px;
    margin-top: 0%;
    font-family: Poppins, sans-serif;
}

#mensagem {
    justify-content: center;
    text-align: center;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 16px;
    font-family: Poppins, sans-serif;
}

#avisoExpira {
    display: flex;
    align-items: center;
    justify-content: center;
}

